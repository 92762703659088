import React, { Fragment } from 'react';
import '../assets/layout.scss';

function Layout(props) {
  return (
    <>
      {props.children}
    </>
  );
}

export default Layout;
